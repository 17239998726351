export const generateId = () => {
  const crypto = window.crypto || window.msCrypto;
  return Date.now().toString(36) + crypto.getRandomValues(new Uint32Array(1)).toString(36).substring(2);
};

export const updateOrAddCVFields = (dataFieldsList, itemField) => {
  let newArr = [...dataFieldsList];

  if (itemField?.id) {
    const findIndex = newArr.findIndex((item) => item.id === itemField.id);
    newArr[findIndex !== -1 ? findIndex : newArr.length] = itemField;
  } else {
    newArr = [...newArr, { ...itemField, id: generateId() }];
  }

  return newArr;
};
